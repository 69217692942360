<template lang="pug">
q-scroll-area.full-width.cards_fix(
    :thumb-style="{display: 'none'}"
    style="height: 100px;"
    v-if="!fixedCards")
    .row.no-wrap.q-mt-xs(style="min-height: 88px")
        router-link.link.card(
            v-show="countOfActiveOrders != undefined && countOfActiveOrders != 0"
            :to="city ? `${city.slug}/o` : '/o'"
            title="Заказы")
            q-card.q-py-xs.overflow-hidden.b-radius-10.card__block.full-height(
                style="min-height: 88px; min-width: 84px"
                flat
                class="bg-red-2"
            )
                q-card-section.row.justify-center.items-center.q-pa-xs(style="padding: 4px 4px; min-height: 80px ")
                    img.justify-center.items-center(
                        src="car.gif"
                        height="24px"
                        alt="Доставка"
                    )
                    q-badge(
                        rounded
                        floating
                        color="negative"
                        :style="'top: 4px; right: 16px;'")
                        span.full-width.text-weight-bold.font-10 {{ countOfActiveOrders }}
                    .font-12.text-center.line-height-16.text-primary(v-html="'Активные заказы'")
        template(v-for="card in cards")
            template(v-if="card.condition")
                router-link.link.card.q-ml-md(
                    v-if="card.type == 'link'"
                    :to="city ? `/${city.slug}${card.link}` : card.link"
                    :title="`${card.title}`")
                    q-card.q-py-xs.overflow-hidden.b-radius-10.card__block(
                        style="min-height: 88px; min-width: 84px"
                        flat
                        :class="`bg-${card.bg}`")
                        q-card-section.row.justify-center.items-center.q-pa-xs(style="padding: 4px 4px; min-height: 80px")
                            q-icon.justify-center.items-center(
                                :name="card.t_icon ? `img:${card.icon}` : `etabl:${card.icon}`"
                                size="24px"
                                color="primary")
                                q-badge(
                                    v-if="card.countCondition"
                                    rounded
                                    floating
                                    color="negative"
                                    :style="'top: -4px; right: -10px;'")
                                    span.full-width.text-weight-bold.font-10 {{ card.count }}
                            .font-12.text-center.line-height-16.card__text.text-primary(v-html="card.title")
                .card.q-ml-md(
                    v-else
                    @click="card.method")
                    q-card.q-py-xs.overflow-hidden.b-radius-10.card__block(
                        style="min-height: 88px; min-width: 84px"
                        flat
                        :class="`bg-${card.bg}`")
                        q-card-section.row.justify-center.items-center.q-pa-xs(style="padding: 4px 4px;min-height: 80px ")
                            q-icon.justify-center.items-center(
                                :name="`etabl:${card.icon}`"
                                size="24px"
                                :color="card.color")
                            .font-12.text-center.line-height-14(v-html="card.title" :class="`text-${card.color}`")
loyality-card-popup(
    :show="show"
    @hide="show = false"
)
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import LoyalityCardPopup from 'components/LoyalityCardPopup/LoyalityCardPopup.vue';

export default {
    name: 'MobileCards',
    components: {
        LoyalityCardPopup,
    },
    setup() {
        const store = useStore();

        const show = ref(false);
        const fixedCards = ref(false);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const countOfActiveOrders = computed(() => store.getters['user/USER_COUNT_ACTIVE_ORDERS']);
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        // промокоды
        const promocodes = computed(() => store.getters['basketNew/PROMOCODES']);

        const countOfPromocodes = computed(() => promocodes.value.length);

        const isApp = computed(() => process.env.MODE === 'cordova');

        const cards = [
            {
                type: 'nolink',
                icon: 'profile-loyalty',
                bg: 'yellow-5',
                color: 'primary',
                title: 'Карта покупателя',
                condition: userAuthorized.value,
                method: () => show.value = true,
            }, {
                type: 'link',
                icon: 'side-delivery',
                bg: 'purple-3',
                color: 'primary',
                title: 'Бесплатная доставка',
                condition: true,
                link: '/catalog-list-mobile?freeDelivery=true',
            }, {
                type: 'link',
                icon: 'download_app',
                bg: 'lime-4',
                color: 'primary',
                title: 'Скачать приложение',
                condition: !isApp.value,
                link: '/redirect-to-store',
            }, {
                type: 'link',
                icon: 'rx_e',
                bg: 'red-5',
                color: 'primary',
                title: 'Ваши рецепты',
                condition: userAuthorized.value,
                link: '/recipes',
            }, {
                type: 'link',
                icon: 'coupon',
                bg: 'purple-4',
                color: 'primary',
                title: 'Активные промокоды',
                condition: userAuthorized.value,
                countCondition: promocodes?.value?.length,
                count: countOfPromocodes,
                link: '/promocodes',
            }, {
                type: 'link',
                icon: 'heart-mini',
                bg: 'light-blue-5',
                color: 'primary',
                title: 'Избранные товары',
                condition: true,
                link: '/favorites',
            }, {
                type: 'link',
                icon: 'side-locatons',
                bg: 'green-2',
                color: 'primary',
                title: 'Адреса аптек',
                condition: true,
                link: '/drugstores',
            }, {
                type: 'link',
                icon: 'side-sale',
                bg: 'blue-1',
                color: 'primary',
                title: 'Акции и скидки',
                condition: true,
                link: '/marketing-list',
            }, {
                type: 'link',
                icon: 'office_bag.svg',
                bg: 'lime-4',
                color: 'primary',
                title: 'eTabl для бизнеса',
                condition: true,
                link: 'partneram',
                t_icon: true,
            }, {
                type: 'link',
                icon: 'side-partners',
                bg: 'green-8',
                color: 'primary',
                title: 'Наши проекты',
                condition: true,
                link: '/projects',
            },
        ];

        // const goScroll = () => {
        //     if (process.env.CLIENT) {
        //         if (pageYOffset > 82) {
        //             if (fixedCards.value === false) {
        //                 fixedCards.value = true;
        //             }
        //         } else {
        //             if (fixedCards.value === true) {
        //                 fixedCards.value = false;
        //             }
        //         }
        //     }
        // };

        // onMounted(async () => {
        //     // if (process.env.CLIENT) {
        //     //     window.addEventListener('scroll', goScroll);
        //     // }
        // });

        return {
            cards,
            countOfActiveOrders,
            promocodes,
            countOfPromocodes,
            userAuthorized,
            show,
            fixedCards,
            city,
            isApp,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    margin-left: 16px;

    &:last-child {
        margin-right: 16px;
    }

    &__block {
        width: 84px;
        height: 88px;
        overflow: hidden !important;
        box-shadow: 2px 2px 10px rgb(0 0 0 / 5%) !important;

        .q-card__section {
            height: 100%;
        }
    }

    &__text {
        width: 90%;
        letter-spacing: -0.5px !important;
    }
}

// .cards_fix {
//     z-index: 1;
//     position: fixed;
//     top: 0;
// }

</style>
