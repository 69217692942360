<template lang="pug">
q-dialog(
    :model-value="show"
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
    full-width)
    .full-width.bg-secondary.b-radius-0.q-py-md.relative-position.bottom-popup-mobile(
        style="max-height: 100% !important"
    )
        .row.absolute-top-right.q-pt-sm.q-pr-sm
            q-btn(
                flat
                round
                fab-mini
                v-close-popup
                @click="$emit('hide')")
                q-icon(
                    name="etabl:cross"
                    size="xs"
                    color="grey-4")
        .font-16.text-weight-medium.text-center
            | Ваша карта лояльности
        .row.items-center.justify-center.q-pt-lg
            .img-wrapper
                qr-code.qr(
                    :size="qrcode.size"
                    level="H"
                    style="filter: opacity(0.8); color: positive;"
                    :value="qrCodeValue")
                q-img.card-img(src="interface/loyality/loyality-cart.svg" no-spinner loading="eager" alt="Аптека которой доверяют" title="Таблеточка: забота о вашем здоровье")
            .text-center.font-13.text-weight-semi_medium.q-py-lg.q-mx-md Покажите QR-код на кассе в аптеке и получите свою скидку*
            .text-center.font-12.q-mb-md.q-mx-md *Карта покупателя дает вам возможность участвовать в программе лояльности и получить скидку на товары до -20%
</template>

<script>
import { Buffer } from 'buffer';
import QrCode from 'qrcode.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'LoyalityCardPopup',
    components: {
        QrCode,
    },
    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
    },
    setup() {
        const store = useStore();
        
        const qrCodeValue = computed(() =>
            Buffer.from('{"client_card":"' + store.getters['user/USER_CLIENT_CARD'] + '","date":' + Date.now()+ '}').toString('base64'),
        );

        const qrcode = {
            size: 500,
            level: 'H',
        };

        return {
            qrCodeValue,
            qrcode,
        };
    },
};
</script>

<style lang="scss" scoped>
.bottom-popup-mobile {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    padding-bottom: calc(8px + constant(safe-area-inset-bottom));
    padding-bottom: calc(8px + env(safe-area-inset-bottom));
}

.qr {
    z-index: 1;
    position: absolute;
    top: 35%;
    width: calc(100vw - 164px) !important;
    height: calc(100vw - 164px) !important;
    margin-left: 22px;

    @media screen and (min-width: 400px) {
        top: 37%;
    }
}

.card-img {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    height: 100%;
}

.img-wrapper {
    width: calc(100% - 120px);
    height:calc((100vw - 120px) * 1.5);
    min-height: 400px;
}
</style>
